import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, resolveComponent as _resolveComponent, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "main" }

import QHeader from '@/components/header/QHeader.vue'
import QNavbar from '@/components/navbar/QNavbar.vue'

export default /*@__PURE__*/_defineComponent({
  __name: 'LayoutMain',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(QHeader),
    _createVNode(QNavbar),
    _createElementVNode("main", _hoisted_1, [
      _createVNode(_component_router_view)
    ])
  ], 64))
}
}

})