import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "q-navbar-more" }

import type { PropType } from 'vue'
import { h } from 'vue'
import {
  Dropdown as ADropdown,
  Menu as AMenu,
  MenuItem as AMenuItem,
} from 'ant-design-vue'
import { MoreOutlined } from '@ant-design/icons-vue'
import type { INavbarItem } from './interfaces'
import QNavbarItem from '@/components/navbar/QNavbarItem.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'QNavbarMore',
  props: {
  items: {
    type: Array as PropType<INavbarItem[]>,
    default: () => {},
  },
},
  setup(__props) {



return (_ctx: any,_cache: any) => {
  const _component_AButton = _resolveComponent("AButton")!

  return (_openBlock(), _createElementBlock("li", _hoisted_1, [
    _createVNode(_unref(ADropdown), { placement: "topRight" }, {
      overlay: _withCtx(() => [
        _createVNode(_unref(AMenu), null, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.items, (item) => {
              return (_openBlock(), _createBlock(_unref(AMenuItem), {
                key: item.name
              }, {
                default: _withCtx(() => [
                  _createVNode(QNavbarItem, { item: item }, null, 8, ["item"])
                ]),
                _: 2
              }, 1024))
            }), 128))
          ]),
          _: 1
        })
      ]),
      default: _withCtx(() => [
        _createVNode(_component_AButton, {
          icon: h(_unref(MoreOutlined)),
          type: "text"
        }, null, 8, ["icon"]),
        _createVNode(_unref(MoreOutlined))
      ]),
      _: 1
    })
  ]))
}
}

})