import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "q-cookie-agreement" }
const _hoisted_2 = { class: "q-cookie-agreement__content" }
const _hoisted_3 = { class: "q-cookie-agreement__content-action" }

import { ref } from 'vue'
import dayjs from 'dayjs'
import QCookieAgreementMainText from '@/components/cookie-agreement/QCookieAgreementMainText.vue'
import QCookieAgreementDescription from '@/components/cookie-agreement/QCookieAgreementDescription.vue'
import { AGREEMENT_COOKIE } from '@/constants/cookie'


export default /*@__PURE__*/_defineComponent({
  __name: 'QCookieAgreement',
  emits: ['close'],
  setup(__props, { emit: __emit }) {

const emits = __emit

const showDescription = ref(false)

function onSubmit() {
  const date = dayjs().add(6, 'month').toString()
  document.cookie = `${encodeURIComponent(AGREEMENT_COOKIE)}=${encodeURIComponent(true)}; expires=${date}`
  emits('close')
}

function onClose() {
  document.cookie = `${encodeURIComponent(AGREEMENT_COOKIE)}=${encodeURIComponent(false)}`
  emits('close')
}

return (_ctx: any,_cache: any) => {
  const _component_AButton = _resolveComponent("AButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[3] || (_cache[3] = _createElementVNode("h3", null, " Соглашение об использовании файлов cookie ", -1)),
      (showDescription.value)
        ? (_openBlock(), _createBlock(QCookieAgreementDescription, { key: 0 }))
        : (_openBlock(), _createBlock(QCookieAgreementMainText, {
            key: 1,
            onOpen: _cache[0] || (_cache[0] = ($event: any) => (showDescription.value = true))
          })),
      _createElementVNode("span", _hoisted_3, [
        _createVNode(_component_AButton, {
          type: "primary",
          onClick: onSubmit
        }, {
          default: _withCtx(() => _cache[1] || (_cache[1] = [
            _createTextVNode(" Принять и продолжить ")
          ])),
          _: 1
        }),
        _createVNode(_component_AButton, { onClick: onClose }, {
          default: _withCtx(() => _cache[2] || (_cache[2] = [
            _createTextVNode(" Отклонить ")
          ])),
          _: 1
        })
      ])
    ])
  ]))
}
}

})