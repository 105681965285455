import { defineComponent as _defineComponent } from 'vue'
import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "q-navbar-item" }
const _hoisted_2 = { class: "q-navbar-item__ico" }
const _hoisted_3 = { class: "q-navbar-item__title" }
const _hoisted_4 = { class: "q-navbar-item__subtitle" }

import Icon from '@ant-design/icons-vue'
import type { PropType } from 'vue'
import type { INavbarItemProps } from './interfaces'


export default /*@__PURE__*/_defineComponent({
  __name: 'QNavbarItem',
  props: {
  item: {
    type: Object as PropType<INavbarItemProps>,
    default: () => {},
  },

},
  setup(__props) {



return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("li", _hoisted_1, [
    _createVNode(_component_router_link, {
      class: "q-navbar-item__link",
      replace: "",
      to: { name: __props.item.name }
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_unref(Icon), null, {
            component: _withCtx(() => [
              (_openBlock(), _createBlock(_resolveDynamicComponent(__props.item.icon)))
            ]),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_3, _toDisplayString(__props.item.name), 1),
        _createElementVNode("div", _hoisted_4, _toDisplayString(__props.item?.meta?.abbrName), 1)
      ]),
      _: 1
    }, 8, ["to"])
  ]))
}
}

})