import { createStore } from 'vuex'
import type { IRootStore } from './interfaces'
import auth from '@/store/main/auth'
import header from '@/store/main/header'
import webSocket from '@/store/main/webSocket'

export default createStore<IRootStore>({
  modules: {
    auth,
    header,
    webSocket,
  },
})
