import { DefaultAPIInstance } from '@/api'

const CommunicationService = {
  getAppeals(params) {
    return DefaultAPIInstance.get('main/communication/chats', { params })
  },
  downloadDocument(id) {
    return DefaultAPIInstance.get(`main/communication/document/${id}`, { responseType: 'blob' })
  },
  getChatsCategories() {
    return DefaultAPIInstance.get('main/communication/chatcategories')
  },
  getChat(id, params) {
    return DefaultAPIInstance.get(`main/communication/chat/${id}`, params)
  },
  putChat(userId, data) {
    return DefaultAPIInstance.put(`main/communication/chat/${userId}`, data)
  },
  postChat(userId, data) {
    return DefaultAPIInstance.post(`main/communication/chat/${userId}`, data)
  },
  postMessage(data) {
    return DefaultAPIInstance.post('main/communication/message', data)
  },
  getNumberOfUnreadMessages(params) {
    return DefaultAPIInstance.delete('main/communication/unreadMessages', {
      params,
    })
  },
  getUnreadMessages() {
    return DefaultAPIInstance.get('main/communication/unreadmessages')
  },
  postFindPayment(data) {
    const url = 'client/createFindPaymentChat'
    return DefaultAPIInstance.post(url, data)
  },
  getTasks(params) {
    const url = 'main/communication/tasks'
    return DefaultAPIInstance.get(url, { params })
  },
}

export default CommunicationService
