import type { Module } from 'vuex'
import { message } from 'ant-design-vue'
import { Client } from '@stomp/stompjs'
import SockJS from 'sockjs-client'
import type { IRootStore } from '../interfaces'
import type { INotificationState } from './interfaces'
import CommunicationService from '@/services/main/CommunicationService'
import useServiceWorker from '@/composables/useServiceWorker'

const webSocketErrorList = ['Отсутствует токен', 'Не валидный токен', 'Нет прав']

function createWebSocket() {
  try {
    return new SockJS(process.env.VUE_APP_API_WS)
  }
  catch (error) {
    console.error('Ошибка при создании WebSocket:', error)
  }
}

const notification: Module<INotificationState, IRootStore> = {
  state: {
    client: undefined,
    countNotifications: 0,
    task: null,
  },

  getters: {
    countNotifications: state => state.countNotifications,
    getTask: state => state.task,
  },

  mutations: {
    setClient: (state, data) => {
      state.client = data
    },
    setCount: (state, data) => {
      state.countNotifications = data
    },
    setTask: (state, data) => {
      state.task = data
    },
  },

  actions: {
    async getNotifications({ commit }) {
      try {
        const { data } = await CommunicationService.getUnreadMessages()
        commit('setCount', data)
      }
      catch (err: any) {
        message.error(err.response.data?.details)
      }
    },

    updateTask({ commit }, data) {
      commit('setTask', data)
      const tasks = Number(data?.changeObjects?.length) || 0
      const chats = Number(data?.chats?.length) || 0
      const notificationCount = tasks + chats
      commit('setCount', notificationCount)
    },

    async subscribeToRoom({ state }, { userId, authorization }) {
      let subscription
      try {
        subscription = state.client?.subscribe(`/user/${userId}/task`, (message: any) => {
          useServiceWorker().sendMessage({ type: 'task', value: JSON.parse(message.body) })
        }, { authorization })
      }
      catch (error) {
        console.error(`Не удалось подписаться на комнату, ${userId}`, error)
        // TODO: check
        if (webSocketErrorList.includes(String(error))) {
          subscription?.unsubscribe()
        }
      }
    },

    disconnect({ state }) {
      localStorage.removeItem('task')
      state.task = null
      state.client?.deactivate()
    },

    connect({ dispatch, state }, { token, userId }) {
      if (!state.client?.connected) {
        state.client = new Client({
          webSocketFactory: createWebSocket,
          connectHeaders: {
            authorization: `${token}`,
          },
          reconnectDelay: 5000,
          beforeConnect: () => {
            return new Promise((resolve) => {
              resolve()
            })
          },
          onConnect: () => {
            dispatch('subscribeToRoom', { userId, token })
          },
          onDisconnect: (frame) => {
            console.log('onDisconnect', frame)
          },
          onWebSocketError: (error) => {
            console.error('[WebSocket] error:', error)
            if (webSocketErrorList.includes(error)) {
              dispatch('disconnect')
            }
          },
        })
        state.client?.activate()
      }
    },
  },
}

export default notification
