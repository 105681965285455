<template>
  <AConfigProvider
    :locale="locale"
    :theme="theme"
  >
    <AStyleProvider hash-priority="high">
      <component :is="layout" v-if="layout" />
      <QCookieAgreement
        v-if="!agreement"
        @close="agreement = true"
      />
    </AStyleProvider>
  </AConfigProvider>
</template>

<script setup lang="ts">
import { computed, onMounted, onUnmounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import ruRU from 'ant-design-vue/es/locale/ru_RU'
import dayjs from 'dayjs'
import 'dayjs/locale/ru'
import {
  ConfigProvider as AConfigProvider,
  StyleProvider as AStyleProvider,
} from 'ant-design-vue'
import type { ThemeConfig } from 'ant-design-vue/lib/config-provider/context'
import { AGREEMENT_COOKIE } from './constants/cookie'
import QCookieAgreement from '@/components/cookie-agreement/QCookieAgreement.vue'
import { useTheme } from '@/composables/useTheme'

dayjs.locale('ru')
const route = useRoute()
const locale = ref(ruRU)
const interval = ref<number>()
const layout = computed(() => route.meta.layout)
const agreement = ref(true)

const theme: ThemeConfig = {
  components: {
    Modal: {
      padding: 0,
      paddingContentHorizontalLG: 0,
      paddingLG: 0,
      paddingMD: 0,
      paddingXS: 0,
    },
    Tag: {
      colorTextLightSolid: 'currentColor',
    },
  },
}

onMounted(() => {
  useTheme().setTheme('newYear')
  // TODO: скрыть вебсокет
  // if ('serviceWorker' in navigator) {
  //   navigator.serviceWorker.addEventListener('message', event => useServiceWorker().parsingMessage(event.data))
  // }

  // if (store.getters.isAuthenticated) {
  //   useServiceWorker().start()
  // }

  // interval.value = setInterval(() => {
  //   if (store.getters.isAuthenticated) {
  //     useServiceWorker().start()
  //   }
  // }, 10000)

  setTimeout(() => {
    agreement.value = document.cookie.includes(AGREEMENT_COOKIE)
  }, 3000)
})

onUnmounted(() => clearInterval(interval.value))
</script>
