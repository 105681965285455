import axios from 'axios'
import store from '@/store'
import router from '@/router'
import useServiceWorker from '@/composables/useServiceWorker'

const loginConfig = {
  baseURL: process.env.VUE_APP_API_URL,
}
export const LoginAPIInstance = axios.create(loginConfig)

LoginAPIInstance.interceptors.request.use(
  (config) => {
    const updatedConfig = { ...config }
    updatedConfig.headers.Authorization = `Basic ${process.env.VUE_APP_BASIC_AUTH_TOKEN}`
    return updatedConfig
  },
  error => Promise.reject(error),
)

LoginAPIInstance.interceptors.response.use(
  response => response,
  async (error) => {
    if (error?.response?.data?.error_description?.includes('Invalid refresh token')) {
      useServiceWorker().disconnect()
      await store.dispatch('onLogout')
    }

    return Promise.reject(error)
  },
)

// API V3
const defaultConfig = {
  baseURL: process.env.VUE_APP_API_V3,
}
export const DefaultAPIInstance = axios.create(defaultConfig)

// ALTCHA
export const AltchaAPIInstance = axios.create(defaultConfig)

let isRefreshing = false
const refreshSubscribers = []

function subscribeTokenRefresh(onRefreshed) {
  refreshSubscribers.push(onRefreshed)
}

function onRefreshed(token) {
  refreshSubscribers.map(cb => cb(token))
}

DefaultAPIInstance.interceptors.request.use(
  (config) => {
    const updatedConfig = { ...config }
    if (store.getters.isAuthenticated === true) {
      updatedConfig.headers.Authorization = `Bearer ${localStorage.getItem('token')}`
    }
    else {
      updatedConfig.headers.Authorization = `Basic ${process.env.VUE_APP_BASIC_AUTH_TOKEN}`
    }

    return updatedConfig
  },
  error => Promise.reject(error),
)

DefaultAPIInstance.interceptors.response.use(
  response => response,
  async (error) => {
    const { status } = error.response
    const originalRequest = error.config
    if (status === 401) {
      if (!isRefreshing) {
        isRefreshing = true
        return store
          .dispatch('onRefreshToken')
          .then(() => {
            isRefreshing = false
            onRefreshed(localStorage.getItem('token'))
            originalRequest.headers.Authorization = `Bearer ${localStorage.getItem('token')}`
            return DefaultAPIInstance(originalRequest)
          })
          .catch(() => Promise.reject(error))
      }
      return new Promise((resolve) => {
        subscribeTokenRefresh(() => {
          originalRequest.headers.Authorization = `Bearer ${localStorage.getItem('token')}`
          resolve(DefaultAPIInstance(originalRequest))
        })
      })
    }
    if (status === 400 && error?.request?.responseType === 'blob') {
      const errorText = await error.response.data.text()
      const newError = {
        ...error,
        response: {
          ...error.response,
          data: JSON.parse(errorText),
        },
      }
      return Promise.reject(newError)
    }
    if (status === 503) {
      await router.push({ path: '/service-unavailable' })
    }

    return Promise.reject(error)
  },
)
