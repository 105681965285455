import { defineComponent as _defineComponent } from 'vue'
import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "q-header-unverified" }
const _hoisted_2 = { class: "q-header-unverified__top container" }
const _hoisted_3 = { class: "q-header-unverified__logo" }
const _hoisted_4 = { class: "q-header-unverified__title" }

import { useRoute } from 'vue-router'

import Icon, { LogoutOutlined } from '@ant-design/icons-vue'
import useLogout from '@/composables/logout'
import QIconLogo from '@/icons/QIconLogo.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'QHeaderUnverified',
  setup(__props) {

const route = useRoute()
const exit = useLogout()

return (_ctx: any,_cache: any) => {
  const _component_AButton = _resolveComponent("AButton")!

  return (_openBlock(), _createElementBlock("header", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_unref(Icon), null, {
          component: _withCtx(() => [
            (_openBlock(), _createBlock(_resolveDynamicComponent(QIconLogo)))
          ]),
          _: 1
        })
      ]),
      _createElementVNode("h2", _hoisted_4, _toDisplayString(_unref(route).query.name ? _unref(route)?.query.name : _unref(route)?.name), 1),
      _createVNode(_component_AButton, {
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(exit)()))
      }, {
        icon: _withCtx(() => [
          _createVNode(_unref(LogoutOutlined))
        ]),
        default: _withCtx(() => [
          _cache[1] || (_cache[1] = _createElementVNode("span", { class: "q-header-unverified__exit" }, "Выйти", -1))
        ]),
        _: 1
      })
    ])
  ]))
}
}

})