import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { useRouter } from 'vue-router'
import { LeftOutlined } from '@ant-design/icons-vue'
import { Tooltip as ATooltip } from 'ant-design-vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'QHeaderBack',
  setup(__props) {

const router = useRouter()

return (_ctx: any,_cache: any) => {
  const _component_AButton = _resolveComponent("AButton")!

  return (_openBlock(), _createBlock(_unref(ATooltip), {
    class: "q-header-back",
    placement: "bottom"
  }, {
    title: _withCtx(() => _cache[1] || (_cache[1] = [
      _createElementVNode("span", null, "Назад", -1)
    ])),
    default: _withCtx(() => [
      _createVNode(_component_AButton, {
        class: "toggle-back",
        type: "link",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(router).go(-1)))
      }, {
        icon: _withCtx(() => [
          _createVNode(_unref(LeftOutlined), { class: "q-back-icon" })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})