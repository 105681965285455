import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "q-header-nav" }

import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import { FilterOutlined } from '@ant-design/icons-vue'
import { computed } from 'vue'
import type { IRouteMeta } from './interfaces'


export default /*@__PURE__*/_defineComponent({
  __name: 'QHeaderNav',
  setup(__props) {

const store = useStore()
const route = useRoute()

const isFilterBtn = computed<boolean>(() => {
  const { subHeader } = route.meta as IRouteMeta
  return !!subHeader?.left
})

return (_ctx: any,_cache: any) => {
  const _component_AButton = _resolveComponent("AButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[1] || (_cache[1] = _createElementVNode("div", {
      class: "q-header-nav__tabs",
      id: "tabs"
    }, null, -1)),
    (isFilterBtn.value)
      ? (_openBlock(), _createBlock(_component_AButton, {
          key: 0,
          class: "q-header-nav__filter-btn",
          type: "link",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(store).commit('openSub')))
        }, {
          default: _withCtx(() => [
            _createVNode(_unref(FilterOutlined))
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ]))
}
}

})