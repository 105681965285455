import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "q-header-top" }
const _hoisted_2 = { class: "q-header-top__left" }
const _hoisted_3 = {
  key: 1,
  class: "q-header-top__left-logo"
}
const _hoisted_4 = { class: "q-header-top__left-title" }
const _hoisted_5 = { class: "q-header-top__user" }

import { useRoute } from 'vue-router'
import Icon from '@ant-design/icons-vue'
import { Dropdown as ADropdown, message } from 'ant-design-vue'
import { onMounted } from 'vue'
import { useStore } from 'vuex'
import QHeaderNotifications from './QHeaderNotifications.vue'
import QIconLogo from '@/icons/QIconLogo.vue'
import QUserbar from '@/components/ui/userbar/QUserbar.vue'
import QHeaderBack from '@/components/header/QHeaderBack.vue'
import CommunicationService from '@/services/main/CommunicationService'
import type { IStore } from '@/store/interfaces'


export default /*@__PURE__*/_defineComponent({
  __name: 'QHeaderTop',
  setup(__props) {

const route = useRoute()
const store: IStore = useStore()

async function getData() {
  try {
    const { data } = await CommunicationService.getTasks()
    store?.dispatch('updateTask', data)
  }
  catch (err: any) {
    message.error({ content: err?.response?.data?.details })
  }
}

onMounted(() => getData())

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", _hoisted_2, [
      (_unref(route)?.meta?.backBtn)
        ? (_openBlock(), _createBlock(QHeaderBack, { key: 0 }))
        : (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_unref(Icon), null, {
              component: _withCtx(() => [
                (_openBlock(), _createBlock(_resolveDynamicComponent(QIconLogo)))
              ]),
              _: 1
            })
          ])),
      _createElementVNode("h2", _hoisted_4, _toDisplayString(_unref(route)?.meta?.headerTitle ?? _unref(route)?.query?.name ?? _unref(route)?.name), 1)
    ]),
    _cache[0] || (_cache[0] = _createElementVNode("div", {
      class: "q-header-top__steps",
      id: "steps"
    }, null, -1)),
    _cache[1] || (_cache[1] = _createElementVNode("div", {
      class: "q-header-top__buttons",
      id: "headerButtons"
    }, null, -1)),
    _createElementVNode("span", _hoisted_5, [
      _createVNode(_unref(ADropdown), null, {
        overlay: _withCtx(() => [
          _createVNode(QHeaderNotifications)
        ]),
        default: _withCtx(() => [
          _createVNode(QUserbar)
        ]),
        _: 1
      })
    ])
  ]))
}
}

})