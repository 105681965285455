import { register } from 'register-service-worker'

register(`${process.env.BASE_URL}sw.js`, {
  ready() {
    console.log('https://lk.rtneo-irk.ru/')
  },
  registered() {
    console.log('Service worker has been registered.')
  },
  cached() {
    console.log('Content has been cached for offline use.')
  },
  updatefound() {
    console.log('New content is downloading.')
  },
  updated() {
    console.log('New content is available; please refresh.')
    caches.keys().then((names) => {
      names.forEach(name => caches.delete(name))
    })
  },
  offline() {
    console.log('No internet connection found. App is running in offline mode.')
  },
  error(error) {
    console.error('Error during service worker registration:', error)
  },
})
