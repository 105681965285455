import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "q-navbar-exit" }
const _hoisted_2 = { class: "q-navbar-exit__ico" }

import { LogoutOutlined } from '@ant-design/icons-vue'
import useLogout from '@/composables/logout'


export default /*@__PURE__*/_defineComponent({
  __name: 'QNavbarExit',
  setup(__props) {

const exit = useLogout()

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("li", _hoisted_1, [
    _createElementVNode("a", {
      class: "q-navbar-exit__link",
      href: "#",
      onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_unref(exit) && _unref(exit)(...args)), ["prevent"]))
    }, [
      _createElementVNode("i", _hoisted_2, [
        _createVNode(_unref(LogoutOutlined))
      ]),
      _cache[1] || (_cache[1] = _createElementVNode("span", { class: "q-navbar-exit__title" }, " Выйти ", -1))
    ])
  ]))
}
}

})