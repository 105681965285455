import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "q-header" }
const _hoisted_2 = { class: "container" }

import { computed } from 'vue'
import { useRoute } from 'vue-router'
import QHeaderNav from '@/components/header/QHeaderNav.vue'
import QHeaderSub from '@/components/header/QHeaderSub.vue'
import QHeaderTop from '@/components/header/QHeaderTop.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'QHeader',
  setup(__props) {

const route = useRoute()

const isNav = computed<boolean>(() => !!route?.meta?.tabs)
const isSub = computed<boolean>(() => !!route?.meta?.subHeader)

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("header", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(QHeaderTop),
      (isNav.value)
        ? (_openBlock(), _createBlock(QHeaderNav, { key: 0 }))
        : _createCommentVNode("", true),
      (isSub.value)
        ? (_openBlock(), _createBlock(QHeaderSub, {
            key: 1,
            style: _normalizeStyle({
          'border-top': `1px solid ${isNav.value ? 'transparent' : '#f0f0f0'}`,
        })
          }, null, 8, ["style"]))
        : _createCommentVNode("", true)
    ])
  ]))
}
}

})